import Login from 'components/public/Login';

export default function LoginPage() {
  return (
    <div className='h-screen bg-primary-light'>
      <header className='h-16 bg-white text-primary shadow-md flex items-center justify-center'>
        <img
          className='w-1/6'
          src={`/${process.env.NEXT_PUBLIC_APP_NAME.toLowerCase()}.svg`}
          alt={`${process.env.NEXT_PUBLIC_APP_NAME} Logo`}
        />
      </header>
      <main className='p-12 flex justify-center items-center'>
        <Login allowPasswordReset />
      </main>
    </div>
  );
}
